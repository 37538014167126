import { applyMiddleware, createStore } from "redux";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import address_web3 from "./address_web3";
import current_loop from "./current_loop";
import price from "./price";
import percent_farm from "./percent_farm";
import user from "./user";
import active from "./active";

const all = combineReducers({
  address_web3,
  current_loop,
  price,
  percent_farm,
  user,
  active,
});

export default createStore(all, applyMiddleware(thunk));
