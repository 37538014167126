const address_web3 = (state = null, action) => {
    switch (action.type) {
      case "GET_ADDRESS":
        return action.payload;
      default:
        return state;
    }
  };
  
  export default address_web3;
