import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import TreeMenu from "react-simple-tree-menu";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { get_user } from "store/user/action";
import {
  getInfoStaking,
  staking,
  getInfoUser,
  addTree,
  checkApproveStaking,
  submitApproveStaking,
  unStaking,
  withdraw,
  getRefUser
} from "service";

const Loading = () => {
  return (
    <span id="wave">
      <span className="dot"></span>
      <span className="dot"></span>
      <span className="dot"></span>
    </span>
  );
};

const LoadingButton = ({ className }) => {
  return (
    <button className={className}>
      <div className="spinner-border spinner-border-sm" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </button>
  );
};

const Info = (props) => {
  const state = useSelector((state) => state);
  const [load, setLoad] = useState(true);
  const [treeView, setTreeView] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [detail, setDetail] = useState(undefined);
  const [refArr, setRefArr] = useState({
    f1: 0, f2: 0, f3: 0, f4: 0, f5: 0, f6: 0, f7: 0, f8: 0, f9: 0, f10: 0,
  });

  useEffect(() => {
    if (state.address_web3) {
      const arr = [];
      // get_tree(state.address_web3.toLowerCase()).then((res) => {
      //   if (res) {
      //     arr.push(res);
      //     setTreeView(arr);
      //   } else {
      //     setTreeView([]);
      //   }
      _getRefUser(state.address_web3);
      // });
    }
  }, [state.address_web3]);

  const _getRefUser = async (address) => {
    let res = await getRefUser()
    setRefArr(res)
    setLoad(false)
    // console.log(refArr);
  }

  const onClaim = async () => {
    setLoading(true);
    let data = await withdraw();
    // console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Withdraw Success", "Error", 10000);
    }

    setLoading(false);
  }

  return (
    <div className="site-main-content__home">
      <div className="container">
        <div className="col col-12 mb-3">
          <div className="block-item item-ocean-pools">
            <div className="block-item__wrap">
              <h2 className="block-item__title mb-4">User Info</h2>
              <div className="row">
                <div className="col-12 mb-3">
                  Address: {state.address_web3?.slice(0, 4)}...
                  {state.address_web3?.slice(-4)}
                </div>
                <div className="col-12 mb-3">
                  Refer By: {state.user?.ref?.slice(0, 4)}...
                  {state.user?.ref?.slice(-4)}
                </div>
                <div
                  className="col-12 mb-3"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Ref Link:{" "}
                  {`${state?.address_web3?.slice(
                    0,
                    4
                  )}...${state?.address_web3?.slice(-6)}`}
                  <button
                    className="btn btn-primary ml-3"
                    style={{
                      margin: "0px 10px",

                      padding: "0 10px",
                    }}
                    onClick={() => {
                      let text = `${window.location.origin}/bat/login?ref=${state?.address_web3}`;
                      var input = document.createElement("input");
                      input.setAttribute("value", text);
                      document.body.appendChild(input);
                      input.select();
                      document.execCommand("copy");
                      document.body.removeChild(input);

                      NotificationManager.success(
                        "Copy Successful",
                        "Success",
                        10000
                      );
                    }}
                  >
                    Copy
                  </button>
                </div>
                <div className="col-12 col-md-6">
                  <div className="mt-2 mb demo_class">
                    totalF1Active:{" "}
                    {!load ? (refArr?.f1) : <Loading />}
                  </div>
                  <div className="mt-2 mb demo_class">
                    totalF2Active:{" "}
                    {!load ? (refArr?.f2) : <Loading />}
                  </div>
                  <div className="mt-2 mb demo_class">
                    totalF3Active:{" "}
                    {!load ? (refArr?.f3) : <Loading />}
                  </div>
                  <div className="mt-2 mb demo_class">
                    totalF4Active:{" "}
                    {!load ? (refArr?.f4) : <Loading />}
                  </div>
                  <div className="mt-2 mb demo_class">
                    totalF5Active:{" "}
                    {!load ? (refArr?.f5) : <Loading />}
                  </div>
                  <div className="mt-2 mb demo_class">
                    totalF6Active:{" "}
                    {!load ? (refArr?.f6) : <Loading />}
                  </div>
                  <div className="mt-2 mb demo_class">
                    totalF7Active:{" "}
                    {!load ? (refArr?.f7) : <Loading />}
                  </div>
                  <div className="mt-2 mb demo_class">
                    totalF8Active:{" "}
                    {!load ? (refArr?.f8) : <Loading />}
                  </div>
                  <div className="mt-2 mb demo_class">
                    totalF9Active:{" "}
                    {!load ? (refArr?.f9) : <Loading />}
                  </div>
                  <div className="mt-2 mb demo_class">
                    totalF10Active:{" "}
                    {!load ? (refArr?.f10) : <Loading />}
                  </div>

                </div>
                <div className="col-12 col-md-6 mt-3 mt-md-0">
                  <div className="mb demo_class">
                    Total Investment:{" "}
                    {!load ? (
                      (Number(state.user?.totalAmount) / 1e18 || 0).toFixed(2)
                    ) : (
                      <Loading />
                    )}
                  </div>

                  <div className="mt-2 mb demo_class">
                    Branch Sales:{" "}
                    {!load ? (
                      (Number(state.user?.directSales) / 1e18 || 0).toFixed(2)
                    ) : (
                      <Loading />
                    )}
                  </div>
                  <div className="mt-2 mb demo_class">
                    MaxOut:{" "}
                    {!load ? (Number(state.user?.maxOut) / 1e18 || 0).toFixed(2) : <Loading />}
                  </div>

                  <div className="mt-2 mb demo_class">
                    Profits:{" "}
                    {!load ? (Number(state.user?.profit) / 1e18 || 0).toFixed(6) : <Loading />}
                  </div>
                  <div className="mt-2 mb demo_class">
                    Compounding:{" "}
                    {!load ? (
                      (Number(state.user?.compounding) / 1e18 || 0).toFixed(6)
                    ) : (
                      <Loading />
                    )}
                  </div>
                  <div className="mt-2 mb demo_class">
                    Commission:{" "}
                    {!load ? (
                      (Number(state.user?.commission) / 1e18 || 0).toFixed(6)
                    ) : (
                      <Loading />
                    )}
                  </div>
                  <div className="mt-2 mb demo_class">
                    Received:{" "}
                    {!load ? (
                      (Number(state.user?.received) / 1e18 || 0).toFixed(6)
                    ) : (
                      <Loading />
                    )}
                  </div>

                  {loading ? (
                    <LoadingButton className="btn btn-primary" />
                  ) : (
                    <>
                      <button
                        className="btn btn-primary mt-3"
                        onClick={onClaim}
                      >
                        Claim
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
