const current_loop = (state = null, action) => {
  switch (action.type) {
    case "GET_CURRENT_LOOP":
      return action.payload;
    default:
      return state;
  }
};

export default current_loop;
