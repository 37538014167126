import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import { stake_adduser, addTree } from "service";


const LoadingButton = ({ className }) => {
  return (
    <button className={className}>
      <div className="spinner-border spinner-border-sm" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </button>
  );
};

const Login = (props) => {
  const state = useSelector((state) => state);
  const [value, setValue] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);

  useEffect(() => {
    const url = new URL(window.location.href);
    const ref = url.searchParams.get("ref");
    if (ref?.length) {
      setValue(ref);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location]);

  console.log(state);
  if (state.user?.userAddress !== "0x0000000000000000000000000000000000000000" && state.user?.userAddress !== null) {
    // window.location.replace('/')
  }
  const onSubmit = async () => {
    setLoading(true);
    const router = localStorage.getItem("router");

    // if () {
    console.log(value);
    // return;
    // }
    try {
      let abc = await addTree(value);

      console.log(abc);
      if (abc) {
        NotificationManager.success("Success", "Success", 10000);
        window.location.replace('/')

      }
    } catch (error) {
      console.log(error);
      NotificationManager.error("invalid address", "Error", 10000);
    }
    setLoading(false);

    // return;
    // setLoading(true);
    // const address = state.address_web3.toLowerCase();
    // let sponsorId, code;
    // sponsorId = value.split("/")[0].toLowerCase();
    // code = value.split("/")[1];
    // stake_adduser({ address, sponsorId, code }).then((res) => {
    //   if (!res.status && res.msg) {
    //     NotificationManager.error(res.msg, "Error", 10000);
    //   } else {
    //     NotificationManager.success("Success", "Success", 10000);
    //     window.location.replace('/')
    //   }
    //   setLoading(false);
    //   // dispatch(get_user(address));
    //   setTimeout(() => {
    //     props.history.push(router);
    //   }, 1000);
    // });

  };

  const onConnect = () => {
    window.ethereum.enable().then((res) => {
      if (res.length) {
        setCheck(true);
      }
    });
  };

  return (
    <div className="site-main-content__pools">
      <div className="container">
        <div className="manage-your-wallet">
          <div className="modal-dialog" role="document">
            <div
              className="modal-content"
              style={{ background: "rgb(26, 33, 50)" }}
            >
              <div
                className="modal-header"
                style={{ borderBottomColor: "rgba(136, 138, 141, 0.37)" }}
              >
                <h3 className="wallet-title">Add Referrer</h3>
              </div>
              <div className="modal-body ">
                <input
                  className="form-control"
                  style={{
                    background: "rgb(255 255 255)",
                    border: "1px solid rgba(136, 138, 141, 0.37)",
                    color: "#161515",
                  }}
                  placeholder="Ref"
                  defaultValue={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                />
              </div>
              <div
                className="modal-footer"
                style={{ borderTopColor: "rgba(136, 138, 141, 0.37)" }}
              >
                {loading ? (
                  <>
                    <LoadingButton className="btn btn-primary" />
                    <LoadingButton className="btn btn-primary" />
                  </>
                ) : (
                  <>
                    {!check ? (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={onConnect}
                      >
                        Connect Wallet
                      </button>
                    ) : null}
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={!value}
                      onClick={onSubmit}
                    >
                      Confirm
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
