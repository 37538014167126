import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { router } from "routes";

const HasChild = ({ e, changePath }) => {
  const state = useSelector((state) => state);
  return (
    <li className="nav-item dropdown">
      <Link
        className="nav-link dropdown-toggle"
        data-bs-toggle="dropdown"
        to="#"
        role="button"
        aria-expanded="false"
      >
        <img className="nav-link__icon" src={e.icon} alt="" />
        <span className="nav-link__title">{e.title}</span>
      </Link>
      <div className="dropdown-menu" aria-labelledby="navbarDropdown">
        {e.children.map((child, key) => (
          <Link
            className="dropdown-item"
            onClick={changePath}
            to={child.path}
            key={key}
          >
            <span className="nav-link__title">{child.title}</span>
            {state.current_loop?.res?.data?.name === child.symbol ? (
              <img
                className="hot_icon"
                src="/assets/images/icon_promotional.svg"
                alt=""
              />
            ) : null}
          </Link>
        ))}
      </div>
    </li>
  );
};

const NavLink = ({ e, changePath }) => (
  <li className="nav-item">
    {e.component ? (
      <Link to={e.path} className="nav-link" onClick={changePath}>
        <img className="nav-link__icon" src={e.icon} alt="" />
        <span className="nav-link__title">{e.title}</span>
        {e.title == "Promotion" ? (
          <img
            className="hot_icon" style={{ marginLeft: "15px" }}
            src="/assets/images/icon_promotional.svg"
            alt=""
          />
        ) : null}
      </Link>
    ) : (
      <a href={e.path} title="Secured by certik" className="nav-link">
        <img className="nav-link__icon" src={e.icon} alt="" />
        <span className="nav-link__title">{e.title}</span>
      </a>
    )}
  </li>
);

const Sidebar = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const changePath = () => {
    if (window.innerWidth < 967) {
      dispatch({
        type: "ACTIVE",
        payload: false,
      });
    }
  };
  return (
    <div
      className={
        state.active
          ? "site-main-content__navigation navigation-vertical active"
          : "site-main-content__navigation navigation-vertical"
      }
    >
      <div className="nav__wrap" id="style-1">
        <ul className="navbar-nav nav navigation-vertical__nav">
          {router.map((e, i) =>
            e.children ? (
              <HasChild changePath={changePath} key={i} e={e} />
            ) : (
              <NavLink changePath={changePath} key={i} e={e} />
            )
          )}
          {/* <li class="nav-item">
            <a class="nav-link">
            <img class="nav-link__icon" src="/assets/images/trade.png" alt="" />
              <span class="nav-link__title">Trade</span>
            </a>
          </li> */}
          {/* <li class="nav-item">
            <a class="nav-link" href="https://btcfundglobal.com/btcf/info">
              <img class="nav-link__icon" src="/assets/images/chemistry.png" alt="" />
              <span class="nav-link__title">Network Mining</span>
            </a>
          </li> */}
          <li class="nav-item">
            <a class="nav-link" href="https://pancakeswap.finance/swap?inputCurrency=0x101d82428437127bf1608f699cd651e6abf9766e&outputCurrency=0x55d398326f99059fF775485246999027B3197955">
              <img class="nav-link__icon" src="/assets/images/swap.png" alt="" />
              <span class="nav-link__title">BAT Swap</span>
            </a>
          </li>
          <li class="nav-item" >
            <a class="nav-link" href="https://vktok.com">
              <img class="nav-link__icon" src="/assets/images/chemistry.png" alt="" />
              <span class="nav-link__title">Landing</span>
            </a>
          </li>
          <li class="nav-item" >
            <a class="nav-link" href="/assets/batstaking.pdf">
              <img class="nav-link__icon" src="/assets/images/arch.png" alt="" />
              <span class="nav-link__title">PRESENTATION</span>
            </a>
          </li>
          <li class="nav-item" >
            <a class="nav-link" href="https://www.binance.com/en/trade/BAT_USDT?theme=dark&type=spot">
              <img class="nav-link__icon" src="/assets/images/trade.png" alt="" />
              <span class="nav-link__title">Trade BAT</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
