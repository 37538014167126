import { getInfoUser } from "../../service";

const domain = "https://us-central1-safaty-e20ba.cloudfunctions.net";


const get_user = (address) => {
  return async function (dispatch) {
    try {
      const abc = await getInfoUser();
      // console.log('abc ', abc);
      dispatch({
        type: "GET_USER",
        payload: abc,
      });
    } catch (e) {
      dispatch({
        type: "GET_USER",
        payload: null,
      });
    }
  };
};

export { get_user };
