import React from "react";
import { useSelector } from "react-redux";

const Footer = () => {
  const state = useSelector((state) => state);
  return (
    <footer
      className={
        state.active
          ? "site-footer text-center active"
          : "site-footer text-center"
      }
    >
      {/* <div className="container">
        <p className="text-center site-footer__image-logo">
          <img src={turtle} alt="" />
        </p>
        <p className="text-center">Copyright 2021 © by safety</p>
      </div> */}
    </footer>
  );
};

export default Footer;
