import Web3 from "web3";

const get_address = () => {
  return function (dispatch) {
    const web3 = new Web3(Web3.givenProvider);

    setInterval(() => {
      web3.eth.getAccounts().then((accounts) => {
        if (accounts.length) {
          dispatch({
            type: "GET_ADDRESS",
            payload: accounts[0],
          });
        } else {
          dispatch({
            type: "GET_ADDRESS",
            payload: null,
          });
        }
      });
    }, 300);
  };
};

export { get_address };
